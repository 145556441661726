import * as React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Button } from "@components/Button"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { CareerOtherForm } from "@components/Form"
import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import { Lottie } from "@components/Lottie"
import { Employee as EmployeeQuotes } from "@components/Quotes"
import { Seo } from "@components/Seo"
import { Arrow } from "@components/UI"
import { usePageSetup } from "@components/usePageSetup"
import careersLotie from "@lottie/careers.json"
import { splitText } from "@utils"
import { graphql } from "gatsby"

const CareersPage = ({ data }) => {
  const { careers, officeImage, draftImage, studioImage, gatheringImage, outsideImage } = data
  const { themeColor } = usePageSetup({ themeColor: "bg-pink-light", displayGeneralForm: false })

  return (
    <Layout>
      <Seo
        title="Careers"
        description="Explore jobs at JK - a creative marketing agency where being awesome is encouraged, and hard work and talent are rewarded with opportunities to grow."
      />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1
              data-aos="stagger"
              className="text-headline"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "We're going places.",
                  break: "",
                  "text-gray-darkest": "Are you coming?",
                }),
              }}
            />
          </Hero.Title>
          <Hero.Message>
            <p>
              <span className="font-bold">At JK, we care about our work and our people.</span> We succeed as a team, but
              always find time to celebrate our individual victories—both professionally and personally.
            </p>
            <p>
              Our culture is rooted in collaboration, creativity, and respect. Everyone gets a seat at the table—because
              whether you’ve been with us for two days or 20 years, your voice and vision matter. All we ask is that you
              come with an open mind, a positive attitude, and a creative spirit.
            </p>
          </Hero.Message>
        </Hero>
      </section>

      <div className={`lg:pt-clamp-17-32 ${themeColor}`}>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
      </div>

      <main>
        {/* CAREER CAROUSEL */}
        <section className="relative overflow-hidden">
          <div className="container absolute inset-x-0 top-0">
            <div className="aspect-w-667 aspect-h-93 lg:aspect-w-1436 lg:aspect-h-80 extend before:bg-pink-light after:bg-pink-light"></div>
          </div>
          <div className="container relative">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Gallery className="bg-pink after:text-white" />
              </CursorMarker>
              <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div data-aos="fade-up" className="pointer-events-none w-75 h-98 sm:w-110 sm:h-78 lg:w-208 lg:h-150">
                    <GatsbyImage className="h-full" image={getImage(officeImage)} alt="JK Office." />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="200"
                    className="pointer-events-none w-75 h-98 sm:w-118 sm:h-78 lg:w-224 lg:h-150"
                  >
                    <GatsbyImage className="h-full" image={getImage(draftImage)} alt="Planning and discovery." />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="pointer-events-none w-75 h-98 sm:w-62 sm:h-78 lg:h-150 lg:w-117"
                  >
                    <GatsbyImage className="h-full" image={getImage(studioImage)} alt="Studio." />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="400"
                    className="pointer-events-none w-75 h-98 sm:w-118 sm:h-78 lg:w-224 lg:h-150"
                  >
                    <GatsbyImage className="h-full" image={getImage(gatheringImage)} alt="Gathering." />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="500"
                    className="pointer-events-none w-75 h-98 sm:w-62 sm:h-78 lg:h-150 lg:w-117"
                  >
                    <GatsbyImage className="h-full" image={getImage(outsideImage)} alt="Outside." />
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>

        <section id="de-and-i" className="pt-clamp-18-30 pb-clamp-30-55">
          <div className="container flex flex-col lg:flex-row">
            <div className="flex lg:w-1/2 xl:justify-center gap-x-4">
              <div className="grid grid-cols-6 lg:w-11/12 xl:w-10/12 lg:grid-cols-10">
                <div data-aos="fade-up" className="col-span-5 lg:col-span-10 xl:col-start-2 xl:col-span-8">
                  <h2 className="text-clamp-26-32">
                    Be part of a culture where everyone feels equal, valued, and embraced.
                  </h2>
                </div>
                <div data-aos="fade-up" className="col-span-10 mt-10">
                  <Lottie animation={careersLotie} />
                </div>
              </div>
            </div>
            <div className="grid-cols-6 lg:w-1/2 xl:grid mt-clamp-15-18 lg:mt-0">
              <div data-aos="fade-up" className="col-span-5">
                <p>
                  We’re a team of passionate people whose different backgrounds, cultures, skills, and experiences are
                  doorways to big ideas, imaginative solutions, and outstanding creative.
                </p>
                <p>
                  We believe that each of us is responsible for respecting the dignity and diversity of all people and
                  creating an inclusive work environment that encourages unique perspectives, open collaboration, and
                  honest communication.
                </p>
                <p>
                  We are committed to recruiting, recognizing, rewarding, and developing employees based on capability
                  and performance—regardless of any aspect of their identity.
                </p>
                <p>
                  And we pledge to continuously integrate diversity, equity, and inclusion into every part of our agency
                  and every experience people have with us—by always listening, thinking, and doing what is right for
                  our employees, our communities, and society.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="pt-clamp-33-40 pb-clamp-29-47">
          <EmployeeQuotes />
          <div className="container flex justify-center mt-clamp-12-24">
            <div className="flex w-full lg:w-10/12">
              <div data-aos="fade-right" className="ml-auto">
                <Button link={`/about-us/#team`} className="text-gray-darkest">
                  Meet our people
                </Button>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="bg-teal-light py-clamp-33-42">
          <div className="container flex justify-center">
            <div className="lg:w-10/12">
              <div style={{ maxWidth: "350px" }}>
                <h2 data-aos="stagger" className="text-white text-title">
                  <span>Available</span> <span className="block text-gray-dark">positions.</span>
                </h2>
              </div>
              <div className="grid sm:grid-cols-2 gap-x-4 gap-y-clamp-18-23 mt-clamp-17-25">
                {careers.nodes.map((position, index) => {
                  return (
                    <div
                      key={position.id}
                      data-aos="fade-up"
                      data-aos-delay={index % 2 === 0 ? "0" : "100"}
                      className={index % 2 === 0 ? "sm:pr-clamp-7-11" : "sm:pl-clamp-7-11"}
                    >
                      <h3 className="text-clamp-26-32">{position.title}</h3>
                      <div
                        className="careers-excerpt mt-clamp-6-9"
                        dangerouslySetInnerHTML={{ __html: position.career.excerpt }}
                      />
                      <div className="mt-clamp-8-12">
                        <Button link={`/careers/${position.slug}`} className="text-gray-darkest">
                          View position
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="pt-clamp-33-57 pb-clamp-24-32">
          <div className="container flex lg:justify-center">
            <div className="xl:w-10/12">
              <h2
                data-aos="stagger"
                className="text-title"
                dangerouslySetInnerHTML={{
                  __html: splitText({
                    "text-gray-darkest": "Not a match for an",
                    break: "hidden sm:block",
                    "text-teal": "open position?",
                  }),
                }}
              />

              <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                <div className="col-span-8">
                  <p data-aos="fade-up">
                    Don’t let that stop you! We’re always looking for talented, motivated people to join our team, even
                    if your current qualifications aren’t a perfect fit. Send us your resume along with a cover letter
                    about yourself and we’ll see what we can do!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-24">
            <div className="mx-auto xl:w-10/12">
              <CareerOtherForm id="277" subject="General | Application" recipient="elaines@jkdesign.com" />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default CareersPage

export const careersQuery = graphql`
  query careersQuery {
    careers: allWpCareer(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        id
        career {
          excerpt
        }
      }
    }
    officeImage: file(relativePath: { eq: "careers/carousel/office.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    draftImage: file(relativePath: { eq: "careers/carousel/draft.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    studioImage: file(relativePath: { eq: "careers/carousel/studio.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    gatheringImage: file(relativePath: { eq: "careers/carousel/gathering.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    outsideImage: file(relativePath: { eq: "careers/carousel/outside.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    courtney: file(relativePath: { eq: "careers/quote/courtney-balzer.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mike: file(relativePath: { eq: "careers/quote/mike-gerberville.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    barb: file(relativePath: { eq: "careers/quote/barbara-kaulius.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
